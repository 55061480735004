<template>
   <div class="SubscriptionBilling">
      <div v-if="noData"
         class="noData">noch nichts zum verrechnen..</div>
      <div v-else
         class="body">
         <Textarea v-if="showTextArea"
            :readonly="readonly"
            placeholder="Bemerkungen"
            v-model="service.billingText"
            @input="$emit('update')" />
         <div class="grid"
            :style="gridStyle">
            <div v-for="(record, index) in services"
               :key="index"
               class="gridRow">
               <div class="text">{{ record.text }}</div>
               <div class="price">{{ toPrice(record) }}</div>
               <div class="startDate">
                  {{ toEuDate(record.startDate) }}
               </div>
               <div class="to"> - </div>
               <div class="endDate">
                  {{ toEuDate(record.endDate) }}
               </div>
               <div class="value">{{ toValue(record.value) }}</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import InputTime from '@components/Form/InputTime.vue';
import Switcher from '@components/Form/Switcher.vue';

import Textarea from '@components/Form/Textarea.vue';
import { minutes } from 'src/utils/utils'
import { Day } from "@SyoLab/date-time"
export default {
   name: 'SubscriptionBilling',
   components: {
      InputTime,
      Switcher,
      Textarea
   },
   props: {
      service: { required: true, type: Object },
      mode: { required: false, default: 'view' },
      readonly: { required: false, default: false }
   },
   data() {
      return {
      }
   },
   methods: {
      toCHF(value) {
         if (!value) return '--'
         return Number(value).toFixed(2)
      },
      toValue(value) {
         if (!value) return null
         return Number(value).toLocaleString('de-ch', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
         })
      },
      toEuDate(isoDate) {
         if (!isoDate) return null
         return new Day(isoDate).toEuDate()
      },
      toPrice(record) {
         if (record.monthly) {
            return Number(record.monthly).toLocaleString('de-ch', {
               style: 'decimal',
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
            }) + '/Mt'
         }
         if (record.yearly) {
            return Number(record.yearly).toLocaleString('de-ch', {
               style: 'decimal',
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
            })
         }
      },
   },
   computed: {
      services() {
         return this.service.services
      },
      settings() {
         return this.service.settings
      },
      gridColumns() {
         let columns = [
            { id: 'text', title: 'Bezeichnung', width: '1fr' },
            { id: 'price', title: 'Preis', width: '95px' },
            { id: 'startDate', title: 'Von', width: '100px' },
            { id: 'to', title: ' - ', width: 'max-content' },
            { id: 'endDate', title: 'Bis', width: '100px' },
            { id: 'value', title: 'Betrag', width: 'minmax(85px, max-content)' }
         ]
         return columns
      },
      gridStyle() {
         return {
            gridTemplateColumns: this.gridColumns.map(column => `[${column.id}] ${column.width}`).join(' ')
         }
      },
      noData() {
         return this.services.length == 0
      },
      showTextArea() {
         if (this.mode == 'edit') return true
         return this.service.billingText ? true : false
      }
   },
}
</script>

<style scoped>
.SubscriptionBilling {
   display: inline-flex;
   flex-direction: column;
   row-gap: 10px;
   padding: 15px 0;
}

.SubscriptionBilling>.title {
   font-size: 22px;
   font-weight: 300;
}

.Textarea {
   height: 22px;
   padding-left: 0;
}


.body {
   display: flex;
   flex-direction: column;
   row-gap: 5px;
}

.body .taskName {
   font-weight: 500;
   line-height: 18px;
}

.noData {
   color: #a6a6a6;
}

.gridRow {
   display: contents;
}

.grid {
   display: grid;
   width: 100%;
}

.gridRow>* {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   height: 24px;

   .text {
      grid-column: text;
   }
}

.gridRow.notBillable {
   color: #a9a8a8;
}

.grid .description {
   grid-column: description;
}

.grid .startDate {
   grid-column: startDate;
   padding: 0 5px;
   display: flex;
   justify-content: center;
}

.grid .endDate {
   grid-column: endDate;
   padding: 0 5px;
   display: flex;
   justify-content: center;
}

.grid .price {
   grid-column: price;
   padding: 0 5px;
   display: flex;

}

.grid .discount {
   grid-column: discount;
   padding: 0 5px;
   display: flex;
   justify-content: flex-end;
}

.grid .value {
   grid-column: value;
   padding-left: 5px;
   justify-content: flex-end;
}
</style>